import { Button, CircularProgress } from "@mui/material";
import React from "react";
import useKeyboardVisibility from "./global/useKeyboardVisibility";

type Props = {
    handle_submission: () => void;
    disabled?: boolean;
    loading?: boolean;
}

const Create_Button = (props: Props) => {

    function handle_click() {
        props.handle_submission();
    }

    const { isKeyboardVisible, keyboardOffset } = useKeyboardVisibility();

    return (<>
        {!props.loading && <Button
            sx={{
                position: 'fixed',
                bottom: 16 + (isKeyboardVisible ? keyboardOffset : 0),
                left: '50%',
                transform: 'translateX(-50%)',
                justifyContent: 'center',
                zIndex: 999,
            }}
            size={"large"}
            disabled={props.disabled ?? false}
            variant="contained"
            onClick={() => handle_click()}>
            {`Create your build!`}
        </Button>
        }
        {props.loading && <CircularProgress sx={{
            position: 'fixed',
            bottom: 16 + (isKeyboardVisible ? keyboardOffset : 0),
            transform: 'translateX(-50%)',
            left: '50%',
            justifyContent: 'center',
            zIndex: 999,
        }} />}
    </>);
}

export default Create_Button;